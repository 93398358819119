<template>
    <footer class="footer">

        <div class="bottom">
            <div class="container">
                <p>
                    Услугу подбора предложений предоставляет Усов Алексей Виикторович, ИНН: 526004541390, ОГРНИП: 306526023600011. Юридический адрес:  603093, Нижегородская область, г Нижний Новгород, ул. Усилова, д. 8., кв. 8.
                </p>
                <br>
                <p>Выдача займов у партнеров осуществляется в российских рубля, на банковский счет, наличными или на карту, гражданам РФ. Сайт не относится к финансовым учреждениям, не является кредитором или банком и не несет ответственности за заключенные впоследствие клиентами и партерами договоры микрокредитования. Все сведения на сайте носят информационный характер. Все рекомендуемые на сайте учреждения имеют соответствующие лицензии.</p>
            </div>
        </div>
    </footer>
</template>

<script>
import contacts from '@/mixins/contacts';

export default {
    name: 'Footer',
    mixins: [
        contacts
    ],

}
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 128px;
}
.description {
  padding: 58px 0;
  background-color: $sub-background;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.07em;

  &::v-deep {
    a {
      color: #2A3555;
      font-weight: 600;
    }
    p + p {
      margin-top: 32px;
    }
  }
}
//.cabinet-footer {
//  .container {
//    max-width: 100%;
//    padding: 58px 0 0 0;
//    //border-top: 1px solid $primary-grey;
//  }
//  .description {
//    padding: 0 95px;
//    background-color: transparent;
//  }
//  .wrapper {
//    margin-left: 0;
//  }
//  .bottom .container {
//    border: none;
//    padding: 0 95px;
//  }
//}
.bottom {
  padding: 84px 0;
}
.wrapper {
  margin-left: 76px;
  a, span {
    display: block;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.07em;
    color: inherit;
  }
  a + a, a + span {
    margin-top: 12px;
  }
}
.link {
  padding: 12px;
  min-width: 198px;
}
@media(max-width: $tablet) {
  .bottom {
    padding: 64px 0;
  }
  //.cabinet-footer {
  //  .container {
  //    padding: 0 40px;
  //    border: none;
  //  }
  //  .wrapper {
  //    margin-left: 76px;
  //  }
  //  .description {
  //    padding: 58px 0;
  //    background-color: #EFF3F9;
  //  }
  //  .bottom {
  //    background: #fafafa;
  //    .container {
  //      padding: 0 40px;
  //    }
  //  }
  //}
}
@media(max-width: $mobile) {
  .footer {
    margin-top: 64px;
    .container {
      padding: 0 24px;
    }
  }
  .description {
    padding: 28px 0 46px 0;
    font-size: 12px;
    line-height: 18px;
    &::v-deep p + p {
      margin-top: 18px;
    }
  }
  //.cabinet-footer {
  //  .container {
  //    padding: 0 24px;
  //  }
  //  .wrapper {
  //    margin-left: 0;
  //  }
  //  .description {
  //    padding: 40px 0 28px 0;
  //  }
  //  .bottom .container {
  //    padding: 0 24px;
  //  }
  //}
  .bottom {
    padding: 64px 0;
  }
  .logo {
    order: 1;
  }
  .parent {
    flex-direction: column;
  }
  .wrapper {
    margin: 0;
    order: 3;
    a:not(.link), span {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .link {
    margin: 32px 0;
    order: 2;
  }
}
</style>