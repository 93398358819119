<template>
    <div class="application-container">
        <Final />
    </div>
</template>

<script>
import './style/reset.scss'
import './style/common.scss'
import Final from '@/applications/final/App'
import { mapGetters } from 'vuex'

export default {
    name: 'App',
    computed: {
        ...mapGetters({
            loader: 'application/loader',
            isError: 'error/isError'
        })
    },
    watch: {
        loader(v) {
            if (!v) {
                document.querySelector('.loader-wrapper').setAttribute('style', 'display:none')
                document.body.removeAttribute('style')
            } else {
                document.querySelector('.loader-wrapper').setAttribute('style', 'display:none')
                document.body.setAttribute('style', 'overflow:hidden;pointer-events:none;user-select:none;')
            }
        }
    },
    components: {
        Final
    }
}
</script>

<style>
.application-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
</style>