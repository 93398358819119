<template>
    <div class="step-t">
        <div class="step-t__header">
            <div class="step-t__header-wrapper">
                <Header/>
            </div>
        </div>
        <div class="step-t__wrapper">
            <div class="step-t__content-wrapper">
                <div class="step-t__content">
                    <div>
                        <h1
                            v-if="$slots.title || $slots.headerTitle"
                            class="step-t__title"
                            :class="titleClass"
                        >
                            <slot
                                v-if="$slots.headerTitle"
                                name="headerTitle"
                            />
                            <slot
                                v-else
                                name="title"
                            />
                        </h1>
                        <div
                            v-if="$slots.top"
                            class="step-t__top"
                            :class="topClass"
                        >
                            <slot name="top"/>
                        </div>
                    </div>
                    <slot/>
                </div>
            </div>
        </div>

        <Footer class="m-0" />
    </div>
</template>

<script>
import './step-t.scss'
import Header from '@/components/common/header/Header'

export default {
    name: 'StepTemplate',
    components: {
        Footer: () => import('@/components/common/footer/Footer'),
        Header,
    },
    props: {
        topClass: {
            type: String,
            required: false,
        },
        titleClass: {
            type: String,
            required: false,
        }
    }
}
</script>