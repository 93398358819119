<template>
    <div style="width: 100%">

    </div>
</template>

<script>
import './header.scss';
import contacts from '@/mixins/contacts';
import mobile from '@/mixins/mobile';

export default {
    name: 'Header',
    mixins: [
        contacts,
        mobile
    ],
    data() {
        return {
            menu: false
        };
    },
    methods: {
        openMenu() {
            this.menu = true;
        },
    },
};
</script>
