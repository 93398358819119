<template>
    <div class="loans-list">
        <div class="loans-list__list">
            <Partner v-for="(partner, index) in isArrSorted"
                     :key="partner.id"
                     class="loans-list__item"
                     :data="partner"
                     :initSteps="startSteps"
                     :finalSteps="maxSteps"
                     :offerPos="index"
                     @increase="increaseInitNum"
                     @decrease="decreaseInitNum"
                     @jumpToEnd="getSortedArr"
                     @checkPopup="getActivePopup"
                     @filterActive="filterActiveOffer"
                     @clickActive="addToClickedArr"
            />
        </div>
        <progress-bar
            :initSteps="startSteps"
            :finalSteps="maxSteps"
            :progressBar="initProgressBar"
            :notClicked="notClickedArr"
            v-if="popupIsActive"
        />
    </div>
</template>

<script>
import './loans-list.scss'
import axios from 'axios';

export default {
    name: 'List',
    data() {
        return {
            showMore: false,
            response: {},
            startSteps: 0,
            counter: 0,
            maxSteps: 3,
            initProgressBar: 10,
            progressBarStep: 30,
            popupIsActive: false,
            sortedArr: null,
            notClickedArr: null,
            clickedArr: [],
        }
    },
    created() {
        this.getOffers();
    },
    computed: {
        offers() {
            if (!this.showMore)
                return this.response.targets?.default

            return [
                ...this.response.targets?.default,
                ...this.response.targets?.other,
            ]
        },
        isArrSorted() {
            return this.sortedArr ? this.sortedArr : this.offers;
        },
    },
    methods: {
        async getOffers() {
            const response = await axios.get('/api/final');
            // @TODO предусмотреть ошибку? тест
            this.response = response.data;
        },
        //increase orders steps
        increaseInitNum() {
            this.counter++;
            if (this.counter <= this.maxSteps) {
                this.startSteps++;
                this.initProgressBar += this.progressBarStep;
            }
        },
        //decrease orders steps
        decreaseInitNum() {
            this.counter--;
            if (this.counter < this.maxSteps) {
                this.startSteps--;
                this.initProgressBar -= this.progressBarStep;
            }
        },
        // filtered array
        getSortedArr(pos) {
            const copyArr = [
                ...(this.isArrSorted),
            ].filter((item, n) => n !== pos);
            const deletedItem = [
                ...(this.isArrSorted),
            ].splice(pos, 1);
            const newArr = copyArr.concat(deletedItem);
            this.sortedArr = newArr;
        },
        //show popup
        getActivePopup(state) {
            this.popupIsActive = state;
        },
        //remove active offer
        filterActiveOffer(id) {
            const copyArr = [
                ...(this.notClickedArr ? this.notClickedArr : this.sortedArr),
            ].filter((item) => item.id !== id);
            this.notClickedArr = copyArr;
        },
        // create clicked offers arr
        addToClickedArr(id) {
            const notActive = [...this.sortedArr].filter((item) => item.id === id);
            const newArr = this.notClickedArr.concat(notActive);
            this.notClickedArr = newArr;
        },
    },
    components: {
        Partner: () => import('@/applications/final/components/partner/Partner'),
        ProgressBar: () => import('@/applications/final/components/progress-bar/ProgressBar'),
    }
}
</script>
